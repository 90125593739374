import { isUrl } from '@lanyan/util'
import { last } from 'lodash-es'

export const formatPhoneNumber = (phoneNumber: string) => {
  const regex = /^1[3456789]\d{9}$/

  // 检查输入是否符合手机号格式。
  if (regex.test(phoneNumber)) {
    // 格式化手机号，例如：138 1234 5678
    const formattedNumber = phoneNumber.replace(
      /(\d{3})(\d{4})(\d{4})/,
      '$1 $2 $3',
    )

    return formattedNumber
  }

  // 如果输入不符合手机号格式，返回原始输入。
  return phoneNumber
}

// 从 url 中提取子域名
export const getCname = (urlString: string) => {
  if (isUrl(urlString)) {
    // 匹配到所有含。的字符。
    const match = urlString.match(/^https?:\/\/([^.]+)\./)

    // 提取子域名。
    const subdomain = match && match[1]

    const cname = last(subdomain?.split('-'))

    return cname
  }

  return
}
