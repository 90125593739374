import { RouteRecordRaw } from 'vue-router'

export default {
  path: 'bill',
  redirect: '/bill/list',
  component: () => import('@/views/Course/index.vue'),
  meta: {
    title: '账单管理',
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/Bill/List.vue'),
    },
  ],
} as RouteRecordRaw
