import { message } from 'ant-design-vue'
import { defineStore } from 'pinia'

import { getSchoolBaseInfo } from '@/api/system'
import router from '@/router'
import { ISchool } from '@/types/school'
import { getCname } from '@/utils/common'
import { schoolInfoStorage } from '@/utils/localforage'

interface ICommonState {
  schoolInfo?: ISchool.Detail
}
export const useCommonStore = defineStore('common', {
  state: (): ICommonState => {
    return {
      schoolInfo: undefined,
    }
  },
  getters: {},
  actions: {
    async getSchoolBaseInfo() {
      const cname = import.meta.env.DEV
        ? 'chengguan'
        : getCname(window.location.href)

      const [getSchoolInfoErr, getSchoolInfoRes] = await getSchoolBaseInfo({
        cname: cname!,
      })

      if (getSchoolInfoErr) {
        // 报错就认为是学校不存在，跳转到 404 页面。
        router.replace({
          name: 'NotFound',
        })

        return message.error(getSchoolInfoErr.response?.data.message)
      }

      this.schoolInfo = getSchoolInfoRes.data

      // 设置页面标题。
      this.setSchoolTitle()

      await schoolInfoStorage.setItem(
        'schoolInfo',
        JSON.stringify(this.schoolInfo),
      )
    },
    async getSchoolInfo() {
      const schoolInfo = (await schoolInfoStorage.getItem(
        'schoolInfo',
      )) as string

      if (schoolInfo) {
        this.schoolInfo = JSON.parse(schoolInfo)

        // 设置页面标题。
        this.setSchoolTitle()
      }
    },
    setSchoolTitle() {
      document.title = `${
        this.schoolInfo?.name ? `${this.schoolInfo.name}-` : ''
      }-运营后台`
    },
  },
})
