import { RouteRecordRaw } from 'vue-router'

export default {
  path: 'user',
  redirect: '/user/list',
  component: () => import('@/views/User/index.vue'),
  meta: {
    title: '用户管理',
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/User/List/index.vue'),
    },
    {
      path: 'detail/:id',
      name: 'userDetail',
      component: () => import('@/views/User/Detail/index.vue'),
      meta: {
        title: '用户详情',
      },
    },
    {
      path: 'course-learning-detail/:studentId/:courseId',
      name: 'UserCourseLearningDetail',
      props: true,
      component: () => import('@/views/User/CourseLearningDetail/index.vue'),
      meta: {
        title: '课程学习详情',
      },
    },
  ],
} as RouteRecordRaw
